<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.configuration')} / ${$t('menu.chatbot')} / ${$t('menu.messagesInit')}` }}</v-card-title>
        <v-card-text>
          <Messages></Messages>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import Messages from './form/Messages.vue'

export default {
  components: {
    Messages,
  },
}
</script>
